/*USER STYLES*/

@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap&subset=cyrillic');

* {
    font-family: 'Roboto', sans-serif !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

iframe {
    height: 100% !important;
    border: none !important;
}

*::selection {
    color: #fff !important;
    background: #84be41 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

a {
    color: inherit;
}

td {
    position: relative !important;
}

.MuiLinearProgress-colorPrimary {
    background-color: #84be4130 !important;
}

.MuiLinearProgress-barColorPrimary {
    background-color: #84be41 !important;
}

.mb40 {
    margin-bottom: 40px !important;
}

.mb30 {
    margin-bottom: 30px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}

.mb10 {
    margin-bottom: 10px !important;
}

.pt40 {
    padding-top: 40px !important;
}

.pr5 {
    padding-right: 5px !important;
}

.pr10 {
    padding-right: 10px !important;
}

.PaddingInput input {
    padding: 7px 14px !important;
}

.textCenter {
    text-align: center;
}

.GreenBox {
    box-shadow: 0 0 20px rgba(132, 190, 65, 0.5);
}

.RedBox {
    box-shadow: 0 0 20px rgba(255, 159, 146, 0.5);
}

.inputFile {
    width: 0;
    height: 0;
    z-index: -666;
    visibility: hidden;
    position: absolute;
    top: -666px;
    left: -666px;
}

.TextCenter {
    text-align: center;
}

.DeleteShadow {
    box-shadow: none !important;
}

.DeletePadding {
    padding: 0 !important;
}

header {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
    z-index: 666 !important;
}

.toolbar-root,
.PlotsTable .MuiToolbar-root {
    display: flex;
    flex-wrap: wrap;
}

.PlotsTable .MuiToolbar-root {
    padding-top: 10px;
    padding-bottom: 10px;
}

.DashboardDatepicker {
    /*margin-top: 8px !important;*/
    margin-right: 0 !important;
    border: none !important;
    pointer-events: none;
}

.DashboardDatepicker .MuiInputAdornment-positionEnd {
    margin-left: 0 !important;
}

.DashboardDatepicker button {
    pointer-events: all;
    padding: 5px !important;
}

.DashboardDatepicker .MuiInput-underline:before {
    border: none !important;
}

.DashboardDatepicker input {
    padding: 5px 0;
    font-size: 18px !important;
    pointer-events: none;
    width: 0 !important;
}

.row {
    display: inline-flex;
}

.rightBar .MuiPickersToolbar-toolbarLandscape {
    display: none;
}

.rightBar .MuiPickersStaticWrapper-staticWrapperRoot {
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
}

.rightBar .MuiPickersCalendar-transitionContainer {
    pointer-events: none;
}

.MaxWidth {
    width: 100%;
}

.AuthLogo {
    height: 100%;
}

.CardAction {
    position: relative;
}

.ExpandAction {
    position: absolute !important;
    bottom: -45px;
    right: -10px;
}

.SocialIcon {
    width: 1.5em;
    height: 1.5em;
}

.WeatherIcon {
    width: 5rem;
    height: 5rem;
}

.ConditionIcon {
    width: 1.7rem !important;
    height: 1.7rem !important;
    padding-left: 4px;
}

.WidgetInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.WeatherCity {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: -10px !important;
}

.WeatherDate {
    font-size: 13px;
}

.Spacer {
    width: 100%;
    margin-bottom: 25px;
}

.MuiInputBase-input {
    color: #2d323e !important;
}

.PlotInfoAvatar {
    width: 100% !important;
    max-width: 297px !important;
    height: 220px !important;
    margin-top: 10px;
    cursor: pointer;
}

.fixedInfoWidth {
    min-width: 185px;
    max-width: 200px;
}

.fixedContactWidth {
    min-width: 200px;
}

.fix-table-toolbar .MuiToolbar-root {
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
}

.plotAvatar {
    width: 139px !important;
    height: 103px !important;
}

.containerCol {
    display: flex;
    flex-direction: column;
}

.containerInline {
    display: inline-flex;
    align-items: center;
    padding: 5px;
    min-width: 151px;
}

.PlotsTable table {
    table-layout: unset !important;
}

.BtnGreen {
    background: #84be41 !important;
    color: #fff !important;
    box-shadow: none !important;
    min-width: 40px !important;
    min-height: 40px !important;
}
.BtnRed {
    background: #D16448 !important;
    color: #fff !important;
    box-shadow: none !important;
    min-width: 40px !important;
    min-height: 40px !important;
}
.ButtonTitle {
    padding: 0 10px !important;
}

.MuiPaper-root .MuiToolbar-regular [class*="MuiInput-underline"] {
    width: 100% !important;
    max-width: 320px !important;
}

.MuiPaper-root [class*="MuiInputBase-"] .MuiInputBase-inputAdornedStart {
    text-overflow: ellipsis !important;
}

.MuiReverse {
    flex-direction: row-reverse;
}

.FixedExpansion {
    min-width: 850px !important;
}

.deleteShadow {
    box-shadow: none !important;
}

.removePadding {
    padding-bottom: 0 !important;
}

.verticalBtn {
    width: 100%;
}

.verticalBtn button {
    justify-content: flex-start;
}

.CardAction {
    position: relative;
}

.ExpandAction {
    position: absolute !important;
    bottom: -45px;
    right: -10px;
}

.alignCenter {
    display: flex;
    align-items: center;
}

.InlineControl div {
    flex-direction: row;
}

.react-tel-input .form-control {
    width: 100%;
    font: inherit;
    padding-left: 40px;
}

.react-tel-input .form-control+div:before {
    content: "Номер телефона";
    top: -5px;
    font-size: 11px;
    color: #263238;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -0.05px;
    width: max-content;
}
.react-tel-input .form-control:required+div:before {
    content: "Номер телефона *";
}

.react-tel-input.WATSAPP .form-control+div:before {
    content: "WhatsApp";
}

.react-tel-input.VIBER .form-control+div:before {
    content: "Viber";
}

.react-tel-input .form-control:focus+div:before {
    color: #84be41;
}

.react-tel-input .form-control:focus {
    border-color: #84be41;
    box-shadow: 0 0 0 1px #84be41;
}

.ColorIndicator_0,
.ColorIndicator_1,
.ColorIndicator_2 {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0;
    width: 10px;
    height: 100%;
    /*border-radius: 666px;*/
    display: block;
    color: transparent;
}

.ColorIndicator_0 {
    background: #ff9f92;
}

.ColorIndicator_1 {
    background: #fde499;
}

.ColorIndicator_2 {
    background: #c4ea98;
}

.AvatarBg {
    background: #84be41 !important;
}

.pl5 {
    padding-left: 5px;
}

.Danger {
    background: #e53935 !important;
    color: #fff !important;
}

.ERROR {
    color: #e53935 !important;
}

.react-tel-input.PHONE .flag,
.react-tel-input.VIBER .flag,
.react-tel-input.WATSAPP .flag {
    width: 1.5rem;
    height: 1.5rem;
    background-size: 1.5rem 1.5rem !important;
}

.react-tel-input.PHONE .flag {
    background: url('../resources/images/socials/phone.svg') no-repeat center;
}

.react-tel-input.VIBER .flag {
    background: url('../resources/images/socials/viber.svg') no-repeat center;
}

.react-tel-input.WATSAPP .flag {
    background: url('../resources/images/socials/whatsapp.svg') no-repeat center;
}


@media screen and (max-width: 930px) {
    .MuiMinimize {
        flex-direction: column;
    }

    .MuiReverse {
        flex-direction: column;
    }

    .ListItemTextMobile span {
        display: block;
        width: 100%;
    }

    .ExpansionRole {
        padding-left: 7px;
    }

    .MuiCardContent-root {
        padding: 15px !important
    }
}

.ControlPanel {
    padding-top: 7px !important;
    padding-bottom: 10px !important;
}

.ChartHeader {
    flex-wrap: wrap;
}

.MuiDialog-paper {
    margin: 32px 5px !important;
}

.MuiDialog-paper .MuiIconButton-root {
    padding: 7px !important;
}

.DialogCountersButton {
    margin-top: 10px !important;
}

.TableControl {
    display: inline-flex;
    width: max-content;
    align-items: center;
    position: relative;
}

.MenuMore {
    margin-left: 16px;
}

.AlignCenter {
    display: flex;
    align-items: center;
}

.QRCode,
.QRCanvas {
    position: absolute;
    top: -6666px;
    left: -6666px;
    visibility: hidden;
}

.MuiDialog-paperFullScreen {
    margin: 0 !important;
}

.MuiDialog-paperFullScreen .MuiIconButton-root {
    padding: 1px !important;
}

.pdfBar .MuiToolbar-root {
    justify-content: flex-end;
}

.MuiFormControlLabel-root {
    margin-left: 0 !important;
}

@media screen and (max-width: 959px) {
    .max959 {
        padding-right: 0 !important;
    }

    .MuiCardActions-root.max959 {
        padding-left: 16px !important;
    }
}

@media screen and (min-width: 960px) {
    .AddrForm {
        margin-right: -34px;
    }
}

@media screen and (max-width: 371px) {
    .ChartHeader .SelectChart {
        padding-bottom: 10px;
    }
}

.textGreen,
.DARK .MuiTypography-root.textGreen{
    color: #84be41 !important;
}

.textRed,
.DARK .MuiTypography-root.textRed{
    color: #ff9f92 !important;
}

.DIS,
.DIS * {
    cursor: not-allowed !important;
    pointer-events: none !important;
}


