.DARK .Black {
    background: #1c2025 !important;
}

.DARK .Black * {
    color: #e6e5e8 !important;
}

body app .DARK {
    background-color: #1c2025 !important;
    color: #adb0bb;
}

.DARK input::placeholder {
    color: #adb0bb !important;
}

.DARK hr {
    background-color: rgba(255, 255, 255, 0.12) !important;
}

.DARK .ColorIndicator_0 {
    background: #F80A00;
}

.DARK .ColorIndicator_1 {
    background: #FFBB17;
}

.DARK .ColorIndicator_2 {
    background: #84be41;
}

.DARK .MuiCardActions-root button:disabled {
    color: #adb0bb !important;
    background: #7c7c7c !important;
}

.DARK .MuiOutlinedInput-input {
    color: #e6e5e8 !important;
}

.DARK .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.12) !important;
}

.DARK .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #84be41 !important;
}

.DARK .MuiTextField-root:hover * {
    border-color: #e6e5e8 !important;
}

.DARK .MuiTableHead-root {
    background: #1c2025 !important;
}

.DARK .MuiToolbar-root  {
    border-bottom: rgba(255, 255, 255, 0.7) !important;
}

.DARK .MuiPickersCalendarHeader-daysHeader * {
    color: #e6e5e8 !important;
}

.DARK .MuiBreadcrumbs-ol .MuiChip-root {
    background: #282C34 !important;
    color: #e6e5e8 !important;
}

.DARK .MuiCollapse-container ul *,
.DARK nav ul * {
    color: #adb0bb;
}

.DARK .MuiButton-root[class*="active"] * {
    color: #84be41 !important;
}

.DARK .MuiSnackbarContent-root * {
    color: #fff !important;
}

.DARK .react-tel-input .form-control {
    background: transparent !important;
    border-color: rgba(255, 255, 255, 0.12);
    color: #e6e5e8 !important;
}

.DARK .react-tel-input:hover .form-control {
    border: 1px solid #e6e5e8;
}

.DARK .react-tel-input:active .form-control,
.DARK .react-tel-input:focus .form-control {
    border: 1px solid #84be41 !important;
}

.DARK .react-tel-input .form-control+div:before {
    background: #282C34 !important;
    color: #e6e5e8;
}

.DARK .MuiTypography-root,
.DARK .MuiTypography-root *,
.DARK .MuiFormLabel-root,
.DARK .MuiPickersBasePicker-container *,
.DARK .MuiButtonBase-root * {
    color: #e6e5e8 !important;
}

.DARK .MuiTable-root * {
    border-color: rgba(255, 255, 255, 0.12);
}

.DARK .MuiTable-root main {
    background: #282C34 !important;
}

.DARK .textGreen {
    color: #84be41 !important;
}

.DARK .MuiToolbar-root * {
    color: #e6e5e8 !important;
}

.MuiDrawer-paperAnchorLeft .DARK {
    background: #282C34 !important;
}

.DARK .MuiFormHelperText-root {
    color: #adb0bb !important;
}

.DARK .MenuMore {
    color: #e6e5e8 !important;
}

.DARK .MuiDialog-paperFullScreen .MuiAppBar-colorPrimary{
    background-color: #1c2025 !important;
}