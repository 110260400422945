/**
* Generic Doka Styles for Profile Page
*/
.DemoProfile {
    position: relative;
}

.ThemeChecker {
    position: absolute;
    z-index: 666;
    top: 15px;
    left: 20px;
}

.DemoProfile .banner > button,
.DemoProfile .profile > button {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 1.25em;
    transform: translateX(-50%) translateY(-50%);
    background: #fff;
    color: #333;
    border: none;
    line-height: 1;
    cursor: pointer;
    padding: .25em .75em;
    border-radius: 9999em;
    z-index: 1;
}

.DemoProfile .profile {
    transform:translateY(-50%);
    margin-left:10%;
    width:20%;
    border-radius: 9999em;
    overflow:hidden;
}

.DemoProfile .profile img {
    display: block;
    width: 100%;
}